import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/twitter-follow-hashtag.png';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Twitterハッシュタグキャンペーン"
		  desc="このサンプルはハッシュタグキャンペーンを行う方法です。ユーザーのアカウントから御社のビジネスにツイートすることで、御社のブランドを宣伝し認知を拡大できます。ハッシュタグをつけてツイートでフォロワーに必ず閲覧されます。ユニークなハッシュタグとキャンペーン内容がわかるツイートをしてもらうことでキャンペーンを拡散できます。ハッシュタグを収集してツイートコンテストを開催することもできます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/twitter/follow-hashtag'}
		  type={'Article'}
		  shortName="Twitterハッシュタグキャンペーン"
		  createdAt="2021-11-27"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="Twitterハッシュタグキャンペーン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="Twitterハッシュタグキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/twitter',
		  name: 'Twitterキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"Twitterハッシュタグキャンペーン"}
		description={<>このサンプルはハッシュタグキャンペーンを行う方法です。ユーザーのアカウントから御社のビジネスにツイートすることで、御社のブランドを宣伝し認知を拡大できます。<br/>ハッシュタグは最大３つまで指定でき、検索時に表示されるようにブランドハッシュタグを含めることが大切です。<br/>ハッシュタグを収集してUGCやツイートコンテストを開催することもできます。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS,Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS
		  , Constants.CAMPAIGN_EFFECTS.GET_MIDDLE_SPREAD, Constants.CAMPAIGN_EFFECTS.VERIFIED_BADGE, Constants.CAMPAIGN_EFFECTS.GAIN_RECOGNITION
		  , Constants.CAMPAIGN_EFFECTS.UGC]}
		plan={'ライトプラン以上'}
		promotionId="e439e1f8ca21df9791"
		platformExample={
		  <PlatformExample
			title="その他のTwitterキャンペーン"
			types={['twitterFollowRetweet', 'twitterFollowLike', 'twitterFollowHashtag','twitterToLine','twitterInstantWin','twitterMultipleAccounts']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples